import _ from 'lodash';

export class ParticipanteContatoModel {
  constructor({
    nome,
    telefone = '',
    email,
    setor,
    cargo,
    responsabilidades
  }) {
this.nome = nome;
this.telefone = telefone;
this.email = email;
this.setor = setor;
this.cargo = cargo;
this.responsabilidades = responsabilidades


  }

  get request() {
    let retorno = _.cloneDeep(this);

    retorno.responsabilidades = retorno.responsabilidades?.map((resp) => {
      return{
        responsabilidade: resp.value
      }
    });



    return retorno;
  }
}
