<template>
  <modal-padrao
    ref="modal-contato"
    max-width="95%"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo="$t('modulos.roteiro_execucao_envio_material.modal.titulo')"
    @ok="confirmacaoSalvar"
  >
    <v-form ref="form">
      <input-text
        v-model="form.nome"
        class="col-12"
        :label="$t('modulos.participantes.formulario.contatos.nome')"
        obrigatorio
      />
      <input-text
        v-model="form.setor"
        class="col-12"
        :label="$t('modulos.participantes.formulario.contatos.setor')"
        obrigatorio
      />
      <input-text
        v-model="form.cargo"
        class="col-12"
        :label="$t('modulos.participantes.formulario.contatos.cargo')"
        obrigatorio
      />
      <input-text
        v-model="form.telefone"
        class="col-12"
        :label="$t('modulos.participantes.formulario.contatos.telefone')"
        :mascara="mascaraTelefone(form.telefone)"
        obrigatorio
      />
      <input-text
        v-model="form.email"
        class="col-12 col-md-8"
        :label="$t('modulos.participantes.formulario.contatos.email')"
        obrigatorio
        email
      />
      <input-combobox-text
        v-model="form.responsabilidades"
        class="col-12 col-md-12"
        :label="$t('modulos.participantes.formulario.contatos.responsabilidade')"
        :options="opcoes.responsabilidades"
        multiplos
        somente-combobox
        obrigatorio
        :item-value="'text'"
        append-icon="$downArrow"
        retornar-objeto
      />
    </v-form>
    <v-divider class="my-5" />
  </modal-padrao>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { ParticipanteContatoModel } from '../../../../../common/models/cadastros/ParticipanteContatoModel';
import ParticipanteService from '../../../../../common/services/cadastros/ParticipanteService'

export default {
  
  props: {
    participanteId: { type: String, default: null },
  },
  data() {
    return {
      form: new ParticipanteContatoModel({}),
      opcoes:{
        responsabilidades: [],
      }
     

    };
  },

  methods: {
    abrirModal: function () {
      this.buscarResponsabilidadeEnum()
      this.form = new ParticipanteContatoModel({});
      this.$refs['modal-contato'].abrirModal();
    },
      buscarResponsabilidadeEnum: function () {
      EnumeradorService.buscar('EnumContatoResponsabilidade').then((res) => {
        this.opcoes.responsabilidades = res;
      });
    },
    confirmacaoSalvar: function () {
     const valido = this.$refs.form.validate();
      if (!valido) 
        return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.salvarContato(this.participanteId, this.form.request).then(() => {
        this.$refs['modal-contato'].fecharModal();
        this.$emit('atualizar-contato')
      }).finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      });
      
    },
   
  },
};
</script>
